import {PermissionItem} from "./PermissionItem";

export function SubGroupPermissions() {

  this.id = ''
  this.name = ''
  this.value = ''
  this.description = ''
  this.subGroupPermissionObj = {}
  this.permissions = []

  this.checked = false


  this.getId = () => {
    return this.id
  }
  this.getName = () => {
    return this.name
  }
  this.getDescription = () => {
    return this.description
  }
  this.getValue = () => {
    return this.value
  }
  this.getSubGroupPermissionObj = () => {
    return this.subGroupPermissionObj
  }
  this.getPermissions = () => {
    return this.permissions
  }

  this.setId = (val) => {
    this.id = val
  }
  this.setName = (val) => {
    this.name = val
  }
  this.setDescription = (val) => {
    this.description = val
  }
  this.setValue = (val) => {
    this.value = val
  }
  this.setSubGroupPermissionObj = (val) => {
    this.subGroupPermissionObj = val
  }
  this.setPermissions = (val) => {
    this.permissions = val
  }

}


SubGroupPermissions.prototype.setSubGroupPermissions = function (permission) {
  this.setId(permission?.id)
  this.setName(permission?.display_name)
  this.setDescription(permission.description)
  this.setValue(permission.name)
  this.setSubGroupPermissionObj(permission)
}

/**
 * Copy parent permission to child array
 */
SubGroupPermissions.prototype.setPermissionsWithPermissionObj = function () {
  let newPermissionItem = new PermissionItem()
  newPermissionItem.setPermissionItem(this.getSubGroupPermissionObj())

  this.setPermissions([newPermissionItem])
}

SubGroupPermissions.prototype.setCheckedSubGroup = function () {

  this.checked = true
  this.permissions.map(permission => {
    permission.setCheckedPermission()
  })
}

SubGroupPermissions.prototype.setUnCheckedSubGroup = function () {

  this.checked = false
  this.permissions.map(permission => {
    permission.setUnCheckedPermission()
  })
}

SubGroupPermissions.prototype.checkActivePermissions = function () {

  let flag = false

  this.permissions.map(permission => {
    if(permission.checked){
      flag = true
    }
  })

  this.checked = flag
}
