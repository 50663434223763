<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['manageUsers_UserOf'])"></div>
      <CardLeftBlock
          :name="$t('manageUsers_UserOf.localization_value.value')"
          :value="MU.data.User.userName"
          :backgroundImage="'subuser'"
          class="sub-user"
      >
        <template slot="body">
          <div class="site-link brown text-decoration-underline mt--2">
            <div v-if="isAdmin && MU.data.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('subUsers', MU.data.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['manageUsers_backTo'])"></div>
                <router-link :to="$store.getters.GET_PATHS.mainSettingsManageUsersLink" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('manageUsers_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['manageUsers_EditUser'])"></div>
              {{$t('manageUsers_EditUser.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['manageUsers_CreateUserPlease'])"></div>
              {{$t('manageUsers_CreateUserPlease.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <GeneralInformation
                    :MU="MU"
            />

            <Location
                    :location="MU.data.User"
            />


            <RoleBlock
                :MU="MU"
                :accessRoles="accessRoles"
                @changeRole="$emit('changeRole')"
            />

            <UserGroupSelect
                v-if="userGroups.length > 0 && isAdmin"
                :MU="MU"
                :userGroups="userGroups"
            />

            <Permissions
                    :MU="MU"
                    :ManagePermissions="MU.data.ManagePermissions"
            />

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.mainSettingsManageUsersLink"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto">
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_edit.localization_value.value')"
                  @click.native="$emit('save')"
                  v-bind:class="{'disabled-btn' : $store.getters.getManageUsersBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import GeneralInformation from "../../chunks/GeneralInformation/GeneralInformation";
  import Permissions from "../../chunks/Permissions/Permissions";
  import Location from "../../chunks/Location/Location";
  import RoleBlock from "@/components/modules/ManageUsersModule/components/chunks/RoleBlock/RoleBlock";
  import UserGroupSelect from "../../chunks/UserGroupSelect/UserGroupSelect";



  export default {
    name: "ManageUsersEditUser",

    components: {
      UserGroupSelect,
      RoleBlock,
      Location,
      Permissions,
      GeneralInformation,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    mixins: [mixinDetictingMobile],

    props: {
      MU: Object,
      accessRoles: Array,
      userGroups: Array,
    },

    data() {
      return {
        options: [{}],
      }
    },

    mounted() {

    },

    methods: {

    }
  }
</script>

<style lang="scss">
  .sub-user .card-detail-left__id{
    @media (min-width: 1200px) {
      font-size: 32px;
    }
  }
</style>

