<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['manageUsers_userGroup'])"></div>
      {{$t('manageUsers_userGroup.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['manageUsers_selectUserGroup'])"></div>
        <DefaultSelect
            :options="userGroups"
            :label="$t('manageUsers_selectUserGroup.localization_value.value')"
            :optionsLabel="'name'"
            @change="(val) => {currentUserGroup = val}"
            :selected="currentUserGroup"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['manageUsers_apply'])"></div>
        <MainButton
            :value="$t('manageUsers_apply.localization_value.value')"
            @click.native="applyUserGroup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import MainButton from "../../../../../UI/buttons/MainButton/MainButton";

export default {
  name: "UserGroupSelect",

  components: {
    MainButton,
    DefaultSelect,
  },

  props: {
    MU: Object,
    userGroups: Array,
  },

  data() {
    return {
      currentUserGroup: null
    }
  },

  mounted() {

  },

  methods: {
    applyUserGroup() {
      console.log(this.currentUserGroup, this.MU);
      let permissionsObject = {}
      this.currentUserGroup.permissions.forEach(item => {
        permissionsObject[item.id] = item.name
      })
      this.MU.setPermissionsByUserData(permissionsObject)
    }
  }
}
</script>

<style scoped>

</style>
