
export function PermissionItem() {

  this.id = ''
  this.name = ''
  this.value = ''
  this.description = ''
  this.permissionItemObj = {}

  this.checked = false


  this.getId = () => {
    return this.id
  }
  this.getName = () => {
    return this.name
  }
  this.getDescription = () => {
    return this.description
  }
  this.getValue = () => {
    return this.value
  }
  this.getPermissionItemObj = () => {
    return this.subGroupPermissionObj
  }

  this.setId = (val) => {
    this.id = val
  }
  this.setName = (val) => {
    this.name = val
  }
  this.setDescription = (val) => {
    this.description = val
  }
  this.setValue = (val) => {
    this.value = val
  }
  this.setPermissionItemObj = (val) => {
    this.subGroupPermissionObj = val
  }

}


PermissionItem.prototype.setPermissionItem = function (permission) {
  this.setId(permission?.id)
  this.setName(permission?.display_name)
  this.setDescription(permission.description)
  this.setValue(permission.name)
  this.setPermissionItemObj(permission)
}

PermissionItem.prototype.setCheckedPermission = function () {
  this.checked = true
}

PermissionItem.prototype.setUnCheckedPermission = function () {
  this.checked = false
}
