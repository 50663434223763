<template>
  <div class="fragment" v-if="ManagePermissions.groupPermissions.length > 0">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['manageUsers_Permissions'])"></div>
      {{ $t('manageUsers_Permissions.localization_value.value') }}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__section-label section-label order-create__col custom-col mb-3"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['manageUsers_CheckAllPermissions'])"></div>
        <DefaultCheckbox
            :label="$t('manageUsers_CheckAllPermissions.localization_value.value')"
            :value="checkAll"
            @input="(val) => checkAllPermissions(val, ManagePermissions)"
        />
      </div>
    </div>

    <div class="order-create__row custom-row"
         v-for="(groupPermissions, index) in ManagePermissions.groupPermissions" :key="index">
      <div class="order-create__col custom-col mb-3"
           v-if="groupPermissions.name"
      >
        <div class="order-create__section-label section-label mb-0" style="font-size: 22px">
          <DefaultCheckbox
              class="mt-1"
              :label="''"
              :value="groupPermissions.checked"
              @input="(checked) => changeGroupPermissions(checked, groupPermissions)"
          />
          {{ groupPermissions.name }}
        </div>
      </div>

      <div class="order-create__col custom-col mb-0 ml-3">
        <template
            v-for="(subGroupPermissions, index) in groupPermissions.SubGroupPermissions">
          <div class="order-create__row custom-row"
               v-if="hidePermissionSubGroup(subGroupPermissions)"
               :key="index"
          >
            <div class="order-create__section-label section-label order-create__col custom-col mb-3">
              <DefaultCheckbox
                  :label="subGroupPermissions.name"
                  :value="subGroupPermissions.checked"
                  @input="(checked) => changeSubGroupPermissions(checked, subGroupPermissions)"
              />
            </div>

            <div class="order-create__col custom-col mb-0" v-if="!duplicatePermissionWithSubGroup(subGroupPermissions)">
              <div class="order-create__row custom-row pl-3">
                <div
                    class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-33 custom-col--xs-50"
                    v-for="(permission, index) in subGroupPermissions.permissions" :key="index"
                >
                  <DefaultCheckbox
                      class="fsz12"
                      :label="permission.name"
                      :value="permission.checked"
                      @input="(checked) => changePermissionChecked(checked, permission, subGroupPermissions)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>


    </div>
  </div>
</template>

<script>
import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {PERMISSIONS} from "../../../../../../staticData/permissionsStatic";


export default {
  name: "Permissions",
  components: {
    DefaultCheckbox
  },

  props: {
    MU: Object,
    ManagePermissions: Object,
  },

  data() {
    return {
      checkAll: false,

      PERMISSIONS: PERMISSIONS,

      hideUserPermissionSubGroupUsers: [
          // 'USER_MANAGE_ACCESS_GENERAL',
          // 'VIEW_ADMIN_ROUTE_PREFIX',
          'ADMIN_REPORT_PRODUCTS_GENERAL',
      ],

      hideUserPermissionSubGroupAdmin: [
          // 'USER_MANAGE_ACCESS_GENERAL',
          // 'VIEW_ADMIN_ROUTE_PREFIX',
          'REPORT_PRODUCTS_GENERAL',
      ],
    }
  },

  methods: {

    hidePermissionSubGroup(subGroup) {
      if(this.isDeveloper){
        return true
      }

      if(!this.isAdmin){
        let visible = true
        this.hideUserPermissionSubGroupUsers.map(item => {
          if(this.PERMISSIONS[item] === subGroup.value)
            visible = false
        })
        return visible
      }

      if(this.isAdmin){
        let visible = true
        this.hideUserPermissionSubGroupAdmin.map(item => {
          if(this.PERMISSIONS[item] === subGroup.value)
            visible = false
        })
        return visible
      }

    },

    duplicatePermissionWithSubGroup(subGroupPermissions) {
      return subGroupPermissions.permissions.length === 1 &&
          this._.first(subGroupPermissions.permissions).id === subGroupPermissions.id
    },

    changeSubGroupPermissions(checked, subGroupPermissions) {
      if (checked) {
        subGroupPermissions.setCheckedSubGroup()
        return
      }

      subGroupPermissions.setUnCheckedSubGroup()
    },

    changeGroupPermissions(checked, groupPermissions) {
      if (checked) {
        groupPermissions.setCheckedGroup()
        return
      }

      groupPermissions.setUnCheckedGroup()
    },

    changePermissionChecked(checked, permission, subGroupPermissions) {
      if (checked) {
        permission.setCheckedPermission()
      } else {
        permission.setUnCheckedPermission()
      }

      subGroupPermissions.checkActivePermissions()
    },

    checkAllPermissions(checked, ManagePermissions) {
      if (checked) {
        ManagePermissions.setActiveAllPermission()
      } else {
        ManagePermissions.setInactiveAllPermission()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
/deep/ .fsz12 .default-checkbox__label {
  font-size: 12px;
}
</style>
