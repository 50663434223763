import {GroupPermissions} from "./GroupPermissions";

export function ManagePermissions() {

  this.groupPermissions = []

}


ManagePermissions.prototype.setManagePermissions = function (permissionGroups) {
  if (!permissionGroups || permissionGroups.length === 0) {
    return
  }

  permissionGroups.map(permissionGroup => {
    let newGroupPermission = new GroupPermissions()
    newGroupPermission.setGroupPermission(permissionGroup)
    this.groupPermissions.push(newGroupPermission)
  })

}

ManagePermissions.prototype.setActiveAllPermission = function () {
  this.groupPermissions.map(group => {
    group.SubGroupPermissions.map(subGroup => {
      group.checked = true
      subGroup.checked = true
      subGroup.permissions.map(permission => {
        permission.checked = true
      })
    })
  })
}

ManagePermissions.prototype.setInactiveAllPermission = function () {
  this.groupPermissions.map(group => {
    group.SubGroupPermissions.map(subGroup => {
      group.checked = false
      subGroup.checked = false
      subGroup.permissions.map(permission => {
        permission.checked = false
      })
    })
  })
}

ManagePermissions.prototype.setPermissionsByUserData = function (permissions) {
  this.groupPermissions.map(group => {
    group.SubGroupPermissions.map(subGroup => {
      subGroup.permissions.map(permission => {
        if (Object.hasOwnProperty.call(permissions, permission.id)) {
          group.checked = true
          subGroup.checked = true
          permission.checked = true
        }
      })
    })
  })
}

ManagePermissions.prototype.getPermissionsIds = function () {
  let ids = []
  this.groupPermissions.map(group => {
    group.SubGroupPermissions.map(subGroup => {

      if (subGroup.checked) {
        ids.push(subGroup.id)
      }

      subGroup.permissions.map(permission => {
        if (permission.checked) {
          ids.push(permission.id)
        }
      })
    })
  })
  return ids
}

ManagePermissions.prototype.getPermissionsIdsAsObjectsArray = function () {
  let ids = []
  console.log(1111, this.groupPermissions);
  this.groupPermissions.map(group => {
    group.SubGroupPermissions.map(subGroup => {

      if (subGroup.checked) {
        ids.push({id: subGroup.id})
      }

      subGroup.permissions.map(permission => {
        if (permission.checked && permission.id !== subGroup.id) {
          ids.push({id: permission.id})
        }
      })
    })
  })

  //fix duplicates
  ids = [...new Set(ids)]

  return ids
}
