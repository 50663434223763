<template>
  <div class="fragment"
       v-if="isDeveloper">
    <div class="order-create__section-label section-label">
      User type
    </div>

    <div class="order-create__row custom-row">
      <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;xs-100">-->
        <!--<DefaultSelect-->
            <!--:options="accessRoles"-->
            <!--:label="$t('common_roles.localization_value.value')"-->
            <!--:optionsLabel="'name'"-->
            <!--v-bind:class="{'ui-no-valid': MU.validation.role}"-->
            <!--:errorTxt="$t(`${MU.validationTranslate.role}.localization_value.value`)"-->
            <!--:error="MU.validation.role"-->
            <!--:selected="MU.data.role"-->
            <!--:disabled="creation && $route.query.type !== undefined"-->
            <!--@change="(val) => {MU.data.role = val; $emit('changeRole')}"-->
        <!--/>-->
      <!--</div>-->

      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-if="_.has(MU.data.role, 'id') && SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id === MU.data.role.id && isAdmin"
      >
        <UserSelect
            :validationUser="MU.data.ParentUser.validation.userId"
            :validationTranslateUser="MU.data.ParentUser.validationTranslate.userId"
            :userSelected="MU.data.ParentUser.user"
            :typeSelect="'users'"
            :userEmail="true"
            @changeUser="(val) => {MU.data.ParentUser.setUser(val)}"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import {SYSTEM_ROLES} from "@/staticData/staticVariables";
import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";

export default {
  name: "RoleBlock",

  components: {
    UserSelect,
    // DefaultSelect,
  },

  props: {
    MU: Object,
    accessRoles: Array,
    creation: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      SYSTEM_ROLES: SYSTEM_ROLES,
    }
  },

  mounted() {

  },
}
</script>

<style scoped>

</style>
