<template>
  <div class="fragment">

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['manageUsers_Location'])"></div>
      {{ $t('manageUsers_Location.localization_value.value') }}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
        <DefaultInput
                :label="$t('common_address.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': location.validation.userAddress}"
                :error="location.validation.userAddress"
                :errorTxt="$t(`${location.validationTranslate.userAddress}.localization_value.value`)"
                v-model="location.userAddress"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
        <DefaultInput
                :label="$t('common_city.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': location.validation.userCity}"
                :error="location.validation.userCity"
                :errorTxt="$t(`${location.validationTranslate.userCity}.localization_value.value`)"
                v-model="location.userCity"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
        <DefaultSelect
            :options="options"
            :label="$t('common_country.localization_value.value')"
            v-bind:class="{'ui-no-valid': location.validation.userCountry}"
            :errorTxt="$t(`${location.validationTranslate.userCountry}.localization_value.value`)"
            :error="location.validation.userCountry"
            :selected="location.userCountry"
            :optionsLabel="'label'"
            @change="changeCountry"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100">
        <DefaultSelect
            :options="optionsDistrict"
            :label="$t('common_region.localization_value.value')"
            v-bind:class="{'ui-no-valid': location.validation.userRegion}"
            :error="location.validation.userRegion"
            :errorTxt="$t(`${location.validationTranslate.userRegion}.localization_value.value`)"
            :selected="location.userRegion"
            :optionsLabel="'label'"
            @change="changeRegion"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
        <DefaultInput
                :label="$t('common_postNumber.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': location.validation.userZip}"
                :error="location.validation.userZip"
                :errorTxt="$t(`${location.validationTranslate.userZip}.localization_value.value`)"
                v-model="location.userZip"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {countriesMixin} from "../../../../../../mixins/commonMixins/countriesMixin";

  export default {
    address: "Location",
    components: {
      DefaultSelect,
      DefaultInput,
    },

    mixins: [countriesMixin],

    props: {
      location: Object,
    },

    data() {
      return {
        options: [],
        optionsDistrict: [],
      }
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        this.options = this.getCountriesForRegistration(countries)

        if(this.location.getUserCountry()?.id) {
          this.$store.dispatch('getRegionsByCountry', this.location.getUserCountry()?.id).then(() => {
            this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
          });
        }
      })
    },

    methods: {
      changeCountry(val){
        this.location.setUserCountry(val)

        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
          this.location.setUserRegion('')
        });
      },

      changeRegion(val){
        this.location.setUserRegion(val)
      },
    },

  }
</script>

<style scoped>

</style>
