import {SYSTEM_ROLES} from "@/staticData/staticVariables";

export const manageUsersMixin = {

  methods: {

    getRoles(){
      return this.$store.dispatch('getSystemRoles').then(response => {
        return this.getRespPaginateData(response)
      })
    },


    changeRole(){
      if(!this.MU.data.role?.id){
        this.MU.setPermissions(false)
        return Promise.resolve(false)
      }

      return this.$store.dispatch('fetchPermissionsGroups', `?filter[permissionGroupRole.role_id]=${this.MU.data.role.id}`).then(response => {
        this.MU.setPermissions(false)
        this.MU.setPermissions(this.getRespData(response))
      })
    },



    save(dataSave) {

      if(!this.MU.validationItem(dataSave?.edit)) return

      let data = this.MU.prepareSave(dataSave?.edit)

      if (!dataSave?.edit) {
        data['user_id'] = null
      }

      if (!dataSave?.edit && this.MU.getRole() === SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id) {
        data['user_id'] = this.MU.data.ParentUser.getUserId()
      }

      if (dataSave?.edit) {
        data['user_id'] = this.MU.data.User.getUserId()
      }

      if (dataSave?.edit && this.MU.getRole() === SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id && !this.isDeveloper) {
        data['user_id'] = this.loadUserAuthorizedData?.user?.id
      }

      if (dataSave?.edit && this.MU.getRole() === SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id && this.isDeveloper) {
        data['user_id'] = this.MU.getUserCompany()?.id || null
      }

      if(this.MU.getRole() !== SYSTEM_ROLES.SYSTEM_ROLE_USER.id && this.MU.getRole() !== SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id){
        data['permission_ids'].push(2)
      }




      let saveType = 'createManageUsers'
      console.log(saveType);
      if(dataSave?.edit){
        saveType = 'updateManageUsers'
        data = {
          id: this.$route.params.id,
          data: data
        }
      }

      this.$store.dispatch(saveType, data).then(response => {
        response.status = response?.data?.status || response?.response?.status

        switch (response.status) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (dataSave?.edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsManageUsersLink + this.$store.getters.getManageUsersFilter)
            this.$store.commit('setManageUsersFilter', '')
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


  }

}
