<template>
  <ManageUsersCreateUser
          :MU="MU"
          :accessRoles="accessRoles"
          :userGroups="userGroups"
          @changeRole="changeRole"
          @save="save({edit: true})"
  />
</template>

<script>
  import ManageUsersCreateUser from "./ManageUsersEditUser/ManageUsersEditUser";
  import {ManageUsers} from "../models/ManageUsers";
  import {manageUsersMixin} from "../../../../../mixins/manageUsersMixins/manageUsersMixin";
  import {SYSTEM_ROLE_DEVELOPER, SYSTEM_ROLE_SUPER_ADMIN, SYSTEM_ROLE_USER} from "../../../../../staticData/staticVariables";

  export default {
    name: "ManageUsersEdit",
    components: {
      ManageUsersCreateUser,
    },

    mixins: [manageUsersMixin],

    data() {
      return {
        MU: new ManageUsers(),
        accessRoles: [],
        userGroups: [],
      }
    },

    mounted() {
      this.getRoles().then(accessRoles => {this.accessRoles = accessRoles})

      this.MU.data.User.setUserId(parseInt(this.$route.params.id))

      this.getManageUser()

      if (this.isAdmin) {

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        url = url + myQuery.limit(1000).page(1).url().split('?')[1]

        this.$store.dispatch('fetchManageUsersGroups', url).then(response => {
          this.userGroups = this.getRespPaginateData(response)
        })
      }

    },

    methods: {
      getManageUser(){
        this.$store.dispatch('getManageUsers', this.MU.data.User.getUserId()).then(response => {
          let data = this.getRespData(response)

          this.checkUserEditAccess(data.user)

          this.MU.setItem(data.user)

          this.changeRole().then(()=>{
            this.MU.setPermissionsByUserData(data.permissions)
          }).catch(error => this.createErrorLog(error))
        }).catch(error => this.createErrorLog(error))
      },

      checkUserEditAccess(userData) {
        console.log('++++++++++++++++');
        console.log(userData);
        if (!this.isDeveloper && (userData?.role?.id === SYSTEM_ROLE_DEVELOPER.id || userData?.role?.id === SYSTEM_ROLE_SUPER_ADMIN.id
            || userData?.role?.id === SYSTEM_ROLE_USER.id)) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    }

  }
</script>

<style scoped>

</style>
