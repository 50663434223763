<template>
  <ul>
    <li>
      <StatusIcoBtn v-if="visibleValidation" class="ico-type"
                    :type="characters ? 'active' : 'cancel-red'"/>
      {{ $t('common_passwordHelpCharacters.localization_value.value') }}
    </li>
    <li>
      <StatusIcoBtn v-if="visibleValidation" class="ico-type"
                    :type="lowerCase ? 'active' : 'cancel-red'"/>
      {{ $t('common_passwordHelpLowercase.localization_value.value') }}
    </li>
    <li>
      <StatusIcoBtn v-if="visibleValidation" class="ico-type"
                    :type="upperCase ? 'active' : 'cancel-red'"/>
      {{ $t('common_passwordHelpUppercase.localization_value.value') }}
    </li>
    <li>
      <StatusIcoBtn v-if="visibleValidation" class="ico-type"
                    :type="numbers ? 'active' : 'cancel-red'"/>
      {{ $t('common_passwordHelpNumber.localization_value.value') }}
    </li>
    <li>
      <StatusIcoBtn v-if="visibleValidation" class="ico-type"
                    :type="specialSymbol ? 'active' : 'cancel-red'"/>
      {{ $t('common_passwordSpecial.localization_value.value') }}
    </li>
  </ul>
</template>

<script>
import {hasLowerCase, hasNumber, hasSpecialSymbol, hasUpperCase} from "@/services/validation";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";

export default {
  name: "PasswordRequirements",
  components: {StatusIcoBtn},
  props: {
    value: String,
    visibleValidation: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    value() {
      this.characters = this.value.length > 12
      this.numbers = hasNumber(this.value)
      this.upperCase = hasUpperCase(this.value)
      this.lowerCase = hasLowerCase(this.value)
      this.specialSymbol = hasSpecialSymbol(this.value)
    },
  },

  data() {
    return {
      characters: null,
      numbers: null,
      upperCase: null,
      lowerCase: null,
      specialSymbol: null,
    }
  },


}
</script>

<style scoped>
  li {
    position: relative;
  }

  .ico-type {
    position: absolute;
    left: -20px;
    top: -1px;
  }
</style>