<template>
  <ManageUsersCreateUser
      :MU="MU"
      :accessRoles="accessRoles"
      :userGroups="userGroups"
      @changeRole="changeRole"
      @save="save({edit: false})"
  />
</template>

<script>
import ManageUsersCreateUser from "./ManageUsersCreateUser/ManageUsersCreateUser";
import {ManageUsers} from "../models/ManageUsers";
import {manageUsersMixin} from "../../../../../mixins/manageUsersMixins/manageUsersMixin";
import {
  SYSTEM_ROLE_DEVELOPER, SYSTEM_ROLE_SUB_USER,
  SYSTEM_ROLE_SUPER_ADMIN,
  SYSTEM_ROLE_USER
} from "../../../../../staticData/staticVariables";

export default {
  name: "ManageUsersCreate",
  components: {
    ManageUsersCreateUser,
  },

  mixins: [manageUsersMixin],

  watch: {
    loadUserAuthorizedData() {
      this.setUserId()
      this.checkUserCreateAccess()
    }
  },

  data() {
    return {
      MU: new ManageUsers(),
      accessRoles: [],
      userGroups: []
    }
  },

  mounted() {
    this.getRoles().then(accessRoles => {
      this.accessRoles = accessRoles

      if(this.$route.query.type){
        this.MU.data.role = this._.find(this.accessRoles, {name: this.$route.query.type});
        this.changeRole()
      }

      if (this.isAdmin) {

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        url = url + myQuery.limit(1000).page(1).url().split('?')[1]

        this.$store.dispatch('fetchManageUsersGroups', url).then(response => {
          this.userGroups = this.getRespPaginateData(response)
        })
      }
    })

    if (this.loadUserAuthorizedData) {
      this.setUserId()
      this.checkUserCreateAccess()
    }

  },

  methods: {

    setUserId() {
      this.MU.data.ParentUser.setUserId(parseInt(this.loadUserAuthorizedData?.user?.id))

      // if(!this.isAdmin){
      //   this.MU.data.User.setUser(this.loadUserAuthorizedData?.user)
      // }
    },

    checkUserCreateAccess() {
      //prevent all user types except developer from creating developers/superadmins/users
      if ((this.$route.query.type === SYSTEM_ROLE_DEVELOPER.name || this.$route.query.type === SYSTEM_ROLE_SUPER_ADMIN.name
          || this.$route.query.type === SYSTEM_ROLE_USER.name) && !this.isDeveloper) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
      //prevent admin from being able to create subuser
      if (this.$route.query.type === SYSTEM_ROLE_SUB_USER.name && !this.isDeveloper && this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
    },

  }

}
</script>

<style scoped>

</style>
