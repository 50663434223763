// import {User} from "./User";
import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {ManagePermissions} from "./ManagePermissions";
import {Delivery} from "../../../../globalModels/Delivery";
import {User} from "../../../../globalModels/User";

export function ManageUsers() {

  this.checkValid = checkValid

  this.data = {
    id: '',

    User: new User(),
    userCompany: null,

    ParentUser: new User(),

    role: null,

    Location: new Delivery(),

    ManagePermissions: new ManagePermissions(),

    historyOfChanged: null,

    // User: new User(),
  }


  this.validation = {
    role: false,
    permissions: false,
  }

  this.validationTranslate = {
    role: '',
    permissions: '',
  }

  this.validationTxt = {
    role: false,
    permissions: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getUserCompany = () => {
    return this.data.userCompany
  }
  this.getRole = () => {
    if (this.data.role?.id) {
      return this.data.role.id
    }
    if (this.data.role !== '') {
      return this.data.role
    }
    return ''
  }
  this.getPermissions = () => {
    return this.data.permissions
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setUserCompany = (val) => {
    this.data.userCompany = val
  }
  this.setRole = (val) => {
    this.data.role = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }

}

/**
 * Global Setters
 */

ManageUsers.prototype.setItem = function (user) {
  this.data.User.setUser(user)
  this.setUserCompany(user?.company_owner)
  this.setRole(user?.role ? user.role : '')

  this.setHistoryOfChanged(user?.activityUUID)
}

ManageUsers.prototype.setPermissionsByUserData = function (permissions) {
  this.data.ManagePermissions.setPermissionsByUserData(permissions)
}


ManageUsers.prototype.setPermissions = function (permissions) {
  if (!permissions) {
    this.data.ManagePermissions = new ManagePermissions()
    return
  }
  this.data.ManagePermissions.setManagePermissions(permissions)
}

/**
 * Prepare Data
 */

ManageUsers.prototype.prepareSave = function (edit) {

  let uniquePermissionIds = [...new Set(this.data.ManagePermissions.getPermissionsIds() || [])]
  console.log(uniquePermissionIds);

  let data = {
    'first_name': this.data.User.getUserFirstName(),
    'last_name': this.data.User.getUserLastName(),
    'phone': this.data.User.getUserPhone(),
    'email': this.data.User.getUserEmail(),
    'address': this.data.User.getUserAddress(),
    'city': this.data.User.getUserCity(),
    'country_id': this.data.User.getUserCountry()?.id,
    'country_region_id': this.data.User.getUserRegion(),
    'zip': this.data.User.getUserZip(),
    'role_id': this.getRole(),
    'permission_ids': uniquePermissionIds,
  }



  // else {
  //   data['user_id'] = this.data.ParentUser.getUserId()
  // }

  if (!edit) {
    data['password'] = this.data.User.getUserPassword()
  }

  return data
}


/**
 * Validations
 */

ManageUsers.prototype.validationItem = function (edit) {

  let validationItems = {
    role: this.getRole(),
  }


  let validationOptions = {
    role: {type: ['empty']},
  }

  let validUserLocation = this.data.User.userValidation({
    // userEmail: true,
    userPhone: true,
    userAddress: true,
    userCity: true,
    userCountry: true,
    userRegion: true,
    userZip: true,
    userFirstName: true,
    userLastName: true,
    userPassword: !edit,
  })

  console.log(validUserLocation);
  console.log(this.checkValid(validationItems, validationOptions));

  return (this.checkValid(validationItems, validationOptions) && validUserLocation)
}
