import {filterCountries, filterCountriesUaBel, filterCountriesUSA} from "../../services/filterCountries";

export const countriesMixin = {
  methods: {

    getCountriesForRegistration(countries){
      return filterCountries(countries).countries
    },

    getCountriesForSender(countries){
      return filterCountriesUaBel(countries).countries
    },

    getCountriesForRecipient(countries){
      return filterCountriesUSA(countries).countries
    },

    removeCountryById(countries, removeIDs) {
      this._.remove(countries, function (item) {
        if(removeIDs.indexOf(item.id) > -1){
          return true
        }
      })
      return countries
    }
  }
}
