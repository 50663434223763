<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['manageUsers_GeneralInformation'])"></div>
      {{ $t('manageUsers_GeneralInformation.localization_value.value') }}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
        <DefaultInput
                :label="$t('common_name.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': MU.data.User.validation.userFirstName}"
                :error="MU.data.User.validation.userFirstName"
                :errorTxt="$t(`${MU.data.User.validationTranslate.userFirstName}.localization_value.value`)"
                v-model="MU.data.User.userFirstName"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_surName'])"></div>
        <DefaultInput
                :label="$t('common_surName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': MU.data.User.validation.userLastName}"
                :error="MU.data.User.validation.userLastName"
                :errorTxt="$t(`${MU.data.User.validationTranslate.userLastName}.localization_value.value`)"
                v-model="MU.data.User.userLastName"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['login_createPassword'])"></div>
        <DefaultInput
                :label="$t('login_createPassword.localization_value.value')"
                :type="'password'"
                v-bind:class="{'ui-no-valid': MU.data.User.validation.userPassword}"
                :error="MU.data.User.validation.userPassword"
                :errorTxt="$t(`${MU.data.User.validationTranslate.userPassword}.localization_value.value`)"
                :autocompleteOff="true"
                v-model="MU.data.User.userPassword"
        />
        <div class="mt-3 wfc d-flex">
          <div class="password-help">
            {{ $t('common_passwordHelp.localization_value.value') }}
            <v-popover
                class="site-tooltip"
                offset="5"
                placement="top"
                trigger="hover"
            >
              <TooltipBtn
                  class="tooltip-target"
              />
              <template slot="popover">
                <!--                    <div class="password-help__tooltip" v-html="$t('common_passwordHelpDetail.localization_value.value')"></div>-->
                <div class="password-help__tooltip">
                  <PasswordRequirements
                      :value="MU.data.User.userPassword"
                      :visibleValidation="MU.data.User.validation.userPassword"
                  />
                </div>
              </template>
            </v-popover>
          </div>
        </div>
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
        <DefaultInput
                :label="$t('common_phone.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': MU.data.User.validation.userPhone}"
                :error="MU.data.User.validation.userPhone"
                :errorTxt="$t(`${MU.data.User.validationTranslate.userPhone}.localization_value.value`)"
                v-model="MU.data.User.userPhone"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['login_email'])"></div>
        <DefaultInput
                :label="$t('login_email.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': MU.data.User.validation.userEmail}"
                :error="MU.data.User.validation.userEmail"
                :errorTxt="$t(`${MU.data.User.validationTranslate.userEmail}.localization_value.value`)"
                v-model="MU.data.User.userEmail"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
  import PasswordRequirements from "@/components/coreComponents/PasswordRequirements/PasswordRequirements";
  export default {
    name: "GeneralInformation",
    components: {
      PasswordRequirements,
      TooltipBtn,
      DefaultInput,
    },

    props: {
      MU: Object,
    },

    data() {
      return {
        name: '',
      }
    },


  }
</script>

<style scoped>

</style>
