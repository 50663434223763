import {SubGroupPermissions} from "./SubGroupPermissions";
import {PermissionItem} from "./PermissionItem";
import {isInteger} from "lodash";

export const maxPermissionsSubGroupSort = 1000

export function GroupPermissions() {

  this.id = ''
  this.name = ''
  this.description = ''
  this.SubGroupPermissions = []

  this.checked = false

  this.getId = () => {
    return this.id
  }
  this.getName = () => {
    return this.name
  }
  this.getDescription = () => {
    return this.description
  }

  this.setId = (val) => {
    this.id = val
  }
  this.setName = (val) => {
    this.name = val
  }
  this.setDescription = (val) => {
    this.description = val
  }

}

GroupPermissions.prototype.setGroupPermission = function (permissionGroup) {

  if(!permissionGroup.permissions || permissionGroup.permissions.length === 0){
    return
  }

  this.setId(permissionGroup?.id)
  this.setName(permissionGroup?.name)
  this.setDescription(permissionGroup?.description)

  let newSubGroupPermissions = new SubGroupPermissions()

  permissionGroup.permissions.map((permission, index)=> {

    /**
     * if sort < 100 => this permission is system
     */
    if(permission.sort < 100){
      newSubGroupPermissions.setSubGroupPermissions(permission)
      newSubGroupPermissions.setPermissionsWithPermissionObj()
      setSubGroupPermissionsItem(this, newSubGroupPermissions)
      return
    }

    /**
     * if sort/1000 - is integer => this permission is parent and has Array existing permissions (with sort = sort/1000 + 999)
     */
    if(isInteger(permission.sort/1000) || index === permissionGroup.permissions.length){

      let newSubGroupPermissions = new SubGroupPermissions()
      newSubGroupPermissions.setSubGroupPermissions(permission)

      let newPermissions = this.createPermissionsBySubGroup(permissionGroup.permissions, permission.sort)

      if(newPermissions.length > 0){
        newSubGroupPermissions.setPermissions(newPermissions)
      } else {
        newSubGroupPermissions.setPermissionsWithPermissionObj()
      }

      setSubGroupPermissionsItem(this, newSubGroupPermissions)
    }

  })

  function setSubGroupPermissionsItem(that, subGroupPermissions) {
    that.SubGroupPermissions.push(subGroupPermissions)
    newSubGroupPermissions = new SubGroupPermissions()
  }

}

GroupPermissions.prototype.setCheckedGroup = function () {

  this.checked = true

  this.SubGroupPermissions.map(subGroup => {
    subGroup.checked = true
    subGroup.permissions.map(permission => {
      permission.setCheckedPermission()
    })
  })

}

GroupPermissions.prototype.setUnCheckedGroup = function () {

  this.checked = false

  this.SubGroupPermissions.map(subGroup => {
    subGroup.checked = false
    subGroup.permissions.map(permission => {
      permission.setUnCheckedPermission()
    })
  })
  // this.permissions.map(permission => {
  //   permission.setCheckedPermission()
  // })
}


GroupPermissions.prototype.createPermissionsBySubGroup = function (permissions, sort) {

  let newPermissions = []
  permissions.map(permission => {
    if(permission.sort - sort > 0 && permission.sort - sort < maxPermissionsSubGroupSort){
      let newPermissionItem = new PermissionItem()
      newPermissionItem.setPermissionItem(permission)
      newPermissions.push(newPermissionItem)
    }
  })

  return newPermissions
}
